<template>
  <section>
    <Portal to="headerActions">
      <Link to="/data/cdps/add" isRouter :permission="Permission.create">
        <b-button type="is-primary" :label="$t('global.new', [$tc('menu.cdp')])" icon-left="plus" />
      </Link>
    </Portal>
    <div class="cdps columns">
      <Base-searcher
        class="column"
        api="cdp"
        @updated="onSearcherUpdate"
        queryKey="name"
        :useDropdown="false"
        :placeholder="$t('global.placeholder.tapeA', [$tc('global.name')])"
      >
        <template #default="{ data }">
          <Searcher-card
            v-for="item in data"
            :key="item.id"
            :active="cdp == item"
            :cdp="item"
            @click="onSelect"
          />
        </template>
      </Base-searcher>
      <div class="right column is-full-touch">
        <Card :cdp="cdp" />
      </div>
    </div>
  </section>
</template>
<script>
import BaseSearcher from '@/components/BaseSearcher/Searcher.vue';
import Card from '@/components/CDP/Card';
import SearcherCard from '@/components/CDP/SearcherCard.vue';
import { Link } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    BaseSearcher,
    Card,
    SearcherCard,
    Link
  },
  computed: {
    Permission() {
      return Permissions.CDPs;
    }
  },
  data() {
    return {
      cdp: {}
    };
  },
  methods: {
    onSearcherUpdate({ page, items }) {
      if (page == 1) this.cdp = items[0] || {};
    },
    onSelect(cdp) {
      this.cdp = cdp;
    }
  }
};
</script>
<style lang="sass" scoped>
.searcher
  min-width: 400px
  width: 35%
  max-width: 650px
  ::v-deep .search-field
    margin-bottom: 10px
.right
  display: flex
  flex-flow: column
  width: 65%
  justify-content: space-between
</style>
