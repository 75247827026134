<template>
  <Card>
    <template #header>
      <div class="flex f-jsb f-ai">
        <p>
          {{ $t('menu.cdp') }}
        </p>
      </div>
    </template>
    <div>
      <div class="profile flex">
        <div class="details">
          <h2>{{ $t('global.generalInfo') }}</h2>
          <div class="flex">
            <Card-item
              class="w-200"
              :title="$t('address.address', [''])"
              :value="cdp.address || '-'"
            />
            <div class="flex f-jsb" style="flex-grow: 1">
              <Card-item :title="$t('address.address', [1])" :value="cdp.address_1 || '-'" />
              <Card-item :title="$t('address.state', [2])" :value="cdp.state || '-'" />
              <Card-item :title="$t('address.city')" :value="cdp.city || '-'" />
            </div>
          </div>
          <div class="flex mt-5">
            <Card-item class="w-200" :title="$t('address.zp', [2])" :value="cdp.zipcode || '-'" />
            <Card-item :title="$t('contact.phone')">
              <Phone-viewer :phone="cdp.phone" />
            </Card-item>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex f-full f-ai f-jsb">
        <i-switch
          :value="cdp.is_active"
          :text="$tc('switch.active', cdp.is_active ? 1 : 2)"
          @input="toggleActive"
          :disabled="!cdp.id || loading.toggleActive"
          :loading="loading.toggleActive"
          :permission="Permission.disabled"
        />

        <div class="buttons">
          <Button
            type="is-primary"
            @click="onEdit"
            :label="$t('button.edit')"
            :disabled="!cdp.id"
            :permission="Permission.update"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { Card, CardItem, iSwitch, PhoneViewer, Button } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Card,
    CardItem,
    iSwitch,
    PhoneViewer,
    Button
  },
  computed: {
    Permission() {
      return Permissions.CDPs;
    }
  },
  data() {
    return {
      data: {},
      loading: { toggleActive: false },
      isDisabled: !this.cdp.is_active
    };
  },
  watch: {
    cdp() {
      this.isDisabled = !this.cdp.is_active;
    }
  },
  methods: {
    onEdit() {
      this.$router.push({ path: `/data/cdps/${this.cdp.id}` });
    },
    async toggleActive(is_active) {
      this.loading.toggleActive = true;
      try {
        await this.Api.patch(`cdp/${this.cdp.id}`);
        this.cdp.is_active = is_active;
        this.isDisabled = !is_active;
      } catch (error) {
        const value = this.cdp.is_active;
        this.cdp.is_active = !value;
        setTimeout(() => {
          this.cdp.is_active = value;
        }, 100);
      }
      this.loading.toggleActive = false;
    }
  },
  props: {
    cdp: {
      type: Object,
      default: () => ({}),
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
.card
  width: 100%
  margin: 0
  ::v-deep .card-content
    display: flex
    flex-flow: column
    justify-content: space-between
.profile
  .details
    width: 100%
    h2
      font-size: 18px
      font-weight: bold
      margin-bottom: 15px
.iSwitch
  margin: 0
.buttons
  justify-content: space-between
.w-200
  width: 200px
</style>
